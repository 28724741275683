import { useState } from "react";
import ListingTagsContext from "./ListingTagsContext";

interface props {
    children: JSX.Element | JSX.Element[];
}

export interface ListingTag {
    id: string;
    title: string;
    link: string;
    isProject?: boolean;
    tour3d?: string;
    owner: Owner;
    isExternal?: boolean;
    highlight?: number;
    seaDistanceName?: string;
    legacy_propID?: string;
    tag?: any
};

export const ListingTagsProvider = ({ children }: props) => {
    const [listingTags, setListingTags] = useState<ListingTag[]>([]);

    return (
        <ListingTagsContext.Provider value={{ listingTags, setListingTags }}>
            {children}
        </ListingTagsContext.Provider>
    );
};