import { useEffect, useState } from "react";
import getConfig from "next/config";

const { ybugID } = getConfig().publicRuntimeConfig;

export const BugReport = () => {
	const [show, setShow] = useState(false);
	useEffect(() => {
		if (typeof window !== "undefined" && ybugID) {
			setShow(true);
			// @ts-ignore
			window.ybug_settings = {
				id: ybugID,
				hide_launcher: true,
				language_override: "es",
			};
			var ybug = document.createElement("script");
			ybug.type = "text/javascript";
			ybug.async = true;
			// @ts-ignore
			ybug.src = "https://widget.ybug.io/button/" + window.ybug_settings.id + ".js";
			var s = document.getElementsByTagName("script")[0];
			s.parentNode.insertBefore(ybug, s);
		}
	}, []);

	// @ts-ignore
	const onClick = () => { if(typeof Ybug !== 'undefined' && Ybug ) Ybug.open("feedback") };

	if (!show) return null;

	return (
		<div
			onClick={onClick}
			style={{
				height: 22,
				width: 110,
				textAlign: "center",
				fontSize: 12,
				lineHeight: "23px",
				fontWeight: "bold",
				backgroundColor: "rgb(142, 4, 165)",
				color: "#fff",
				position: "fixed",
				right: -44,
				top: "30%",
				zIndex: 1040,
				padding: "0 10px",
				borderRadius: "8px 8px 0 0",
				transform: "rotate(-90deg)",
				boxShadow: "0 0 10px -2px rgba(0,0,0,0.55)",
			}}>
			Reportar Error
		</div>
	);
};

