import { createContext } from "react";
import { ListingTag } from "./ListingTagsProvider";

export type PropertyDataContextProps = {
    listingTags: ListingTag[];
    setListingTags: (tags: ListingTag[]) => void;
};

const PropertyDataContext = createContext<PropertyDataContextProps>({} as PropertyDataContextProps);

export default PropertyDataContext;