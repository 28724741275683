import { useState } from "react";
import LocationExtraDataContext from "./LocationExtraDataContext";

interface props {
  children: JSX.Element | JSX.Element[];
}

export interface Coordinates {
  lat: number,
  lng: number
}

export const LocationExtraDataProvider = ({ children }: props) => {
  const [locationText, setLocationText] = useState<string>('')
  const [coordinates, setCoordinates] = useState<Coordinates>({} as Coordinates)

  return (
    <LocationExtraDataContext.Provider
      value={{
        locationText,
        setLocationText,
        coordinates,
        setCoordinates
      }}
    >
      {children}
    </LocationExtraDataContext.Provider>
  );
};
