import { createContext } from "react";
import { Coordinates } from "./LocationExtraDataProvider";

export type LocationTextContextProps = {
  locationText: string;
  setLocationText: (type: string) => void;
  coordinates: Coordinates;
  setCoordinates: (data: Coordinates) => void;
};

const LocationTextContext = createContext<LocationTextContextProps>({} as LocationTextContextProps);

export default LocationTextContext;
