import React, { useEffect, useState } from 'react'
import './style.less'
import { useRouter } from 'next/router'
import { WarningIcon } from 'shared-components/Components/CustomIcons/WarningIcon/web'

type Props = {
    data: any
}

const DisclaimerPeru = (props: Props) => {

    const [localState, setLocalState] = useState<boolean>(true)
    const [countryCode, setCountryCode] = useState<string>('')
    const router = useRouter()

    const closeDisclaimer = () => {
        localStorage && localStorage.setItem('disclaimerPeru', Date.now().toString())
        setLocalState(false)
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            try {
                if (localStorage && localStorage.getItem('disclaimerPeru')) {
                    setLocalState(false);
                } else {
                    setLocalState(true);
                }
            } catch (error) {
                console.log(error)
            }
        }
    }, [typeof window]);

    useEffect(() => {
        if (props.data) {
            if (props?.data?.ConfigContextInitialState?.country_code) setCountryCode(props.data.ConfigContextInitialState.country_code)
        }
    }, [props.data, countryCode])

    if (localState && countryCode === 'PE' && router.pathname !== '/home') {
        return <div className="disclaimer-top">
            <div className='disclaimer-top__content'>
                <WarningIcon className='disclaimer-top__content__icon' />
                <p><b>Atención:</b> Nunca transfieras dinero con la promesa de recibir información. Toda la información de las propiedades publicadas en el portal es gratuita y de libre acceso. Si recibiste invitaciones a girar dinero a cambio de ver propiedades denuncia al publicante escribiendo a <a href="mailto:info@infocasas.com.pe">info@infocasas.com.pe</a></p>
                <button className='disclaimer-top__content__doneButton' onClick={() => closeDisclaimer()}>Entendido</button>
            </div>
        </div>
    }
    else return <></>
}



export default DisclaimerPeru