import { useState } from "react";
import PropertyDataContext from "./PropertyDataContext";

interface props {
    children: JSX.Element | JSX.Element[];
}

export interface PropertyData {
	antiquity: any;
    __typename?: string;
    id: string;
    idFincaLegacy?: string | null
    title: string;
    typeID: number
    address?: string
    showAddress?: boolean
    country_id?: number
    description: string
    code: string;
    code2: string
    finances?: boolean
    grouped_ids?: string;
    img?: string;
    slug?: string;
    link: string;
    subtitle: string;
    include_administration?:boolean;
    commonExpenses: CommonExpenses;
    operation_type: OperationType;
    operation_type_id?: number;
    price_variation: PriceVaritation;
    price: Price;
    finalization: string;
    constructionState: ConstructionState;
    price_amount_usd: number;
    temporal_currency?: Currency | null;
    temporal_price?: number | null;
    active: boolean;
    isProject?: boolean;
    isEspecial?: boolean;
    facilities: any;
    images: Image[];
    features?: ProjectFeature[];
    backup_img?: string;
    backup_title?: string;
    tour3d?: string;
    files: FileType[];
    guarantee?: string;
    youtube?: string;
    video?: string;
    image_count: number;
    latitude?: number;
    longitude?: number;
    logo?: string;
    pointType?: boolean;
    property_type: PropertyType;
    bedrooms: number;
    rooms: number;
    bathrooms: number;
    m2?: number;
    minM2?: number;
    hectares: number;
    guests: number;
    technicalSheet: TechnicalSheetType[];
    owner: Owner;
    seller: Seller;
    commercial_units?: CommercialUnit[];
    architect: string;
    percentage_sold: number | null;
    percentage_finished: number | null;
    ocupation: Date | null;
    builder: string;
    isExternal?: boolean;
    highlight?: number;
    occupancies?: OccupancyType[];
    seasons?: Season[];
    seaview?: boolean;
    estate?: Estate;
    neighborhood?: Neighborhood;
    seaDistanceName?: string;
    updated_at?: Date;
    project?: any;
    locations?: any;
    socialMediaLinks?: any;
    tag?: any;
    legacy_propID?: string;
    stratum?: number;
    garage?: number;
    construction_year?: number;
};

const initialStateProperty: PropertyData = {
    id: "",
    idFincaLegacy: "",
    title: "",
    typeID: 0,
    address: "",
    showAddress: false,
    country_id: 0,
    description: "",
    code: "",
    code2: "",
    finances: false,
    grouped_ids: "",
    gc_active: 0,
    img: "",
    slug: "",
    link: "",
    subtitle: "",
    commonExpenses: {
        amount: 0,
        currency: {
            name: "",
            rate: 0,
            id: ""
        },
        hidePrice: false,
        hide: false
    },
    operation_type: {
        id: "",
        name: ""
    },
    price_variation: {
        amount: 0,
        currency: {
            name: "",
            rate: 0,
            id: ""
        },
        hidePrice: false,
        difference: 0,
        percentage: 0,
        date: "",
    },
    price: {
        amount: 0,
        currency: {
            name: "",
            rate: 0,
            id: ""
        },
        hidePrice: false,
    },
    finalization: "",
    constructionState: {
        name: ""
    },
    price_amount_usd: 0,
    temporal_currency: null,
    temporal_price: null,
    active: false,
    isProject: false,
    isEspecial: false,
    facilities: {},
    images: [],
    features: [],
    backup_img: "",
    backup_title: "",
    tour3d: "",
    files: [],
    guarantee: "",
    youtube: "",
    video: "",
    image_count: 0,
    latitude: 0,
    longitude: 0,
    pointType: false,
    property_type: {
        id: "",
        name: ""
    },
    bedrooms: 0,
    rooms: 0,
    bathrooms: 0,
    m2: 0,
    minM2: 0,
    hectares: 0,
    guests: 0,
    technicalSheet: [],
    owner: {
        id: "",
        name: "",
        masked_phone: "",
        type: "",
        logo: "",
        inmoLink: "",
        inmoLinkProps: "",
        inmoPropsLink: "",
        subsidiaries: [],
        has_whatsapp: false,
        inmofull: false,
        particular: false,
    },
    seller: {
        id: "",
        name: "",
        masked_phone: "",
    },
    commercial_units: [],
    architect: "",
    percentage_sold: null,
    percentage_finished: null,
    ocupation: null,
    builder: "",
    isExternal: false,
    highlight: 0,
    occupancies: [],
    seasons: [],
    seaview: false,
    estate: {
        id: 0,
        country_id: 0,
        name: "",
    },
    neighborhood: {
        id: 0,
        name: "",
        estate: {
            id: 0,
            country_id: 0,
            name: "",
        },
        lat: 0,
        long: 0,
    },
    seaDistanceName: "",
    updated_at: null,
    project: {},
    locations: {},
    socialMediaLinks: {},
    tag: {},
};

export const PropertyDataProvider = ({ children, initialListing}) => {
    const [listing, setListing] = useState<PropertyData>(initialListing ?? initialStateProperty);
    const [loading, setLoading] = useState<boolean>(initialListing ? false : true);
    const [similars, setSimilars] = useState<PropertyData[]>([]);
    const [isLoadingSimilars, setIsLoadingSimilars] = useState<boolean>(true);

    return (
        <PropertyDataContext.Provider value={{ listing, setListing, loading, setLoading, similars, setSimilars, isLoadingSimilars, setIsLoadingSimilars }}>
            {children}
        </PropertyDataContext.Provider>
    );
};
