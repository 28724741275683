import { createContext } from "react";
import { PropertyData } from "./PropertyDataProvider";

export type PropertyDataContextProps = {
    listing: PropertyData;
    setListing: (listing: PropertyData) => void;
    loading: boolean;
    setLoading: (isLoading: boolean) => void;
    similars: PropertyData[];
    setSimilars: (listing: PropertyData[]) => void;
    isLoadingSimilars: boolean;
    setIsLoadingSimilars: (isLoading: boolean) => void;
};

const PropertyDataContext = createContext<PropertyDataContextProps>({} as PropertyDataContextProps);

export default PropertyDataContext;